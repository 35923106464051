function onImageLoad(event: Event) {
	const target = event.target;

	if (!(target instanceof HTMLImageElement)) {
		return;
	}

	target.classList.add("_hoa-lazy-image-loaded");
}

document.addEventListener("DOMContentLoaded", () => {
	const observer = new MutationObserver((mutations) => {
		const elements =
			document.querySelectorAll<HTMLImageElement>(`img._hoa-lazy-image`);

		elements.forEach((el) => {
			if (el.complete) {
				el.classList.add("_hoa-lazy-image-loaded-instant");
			} else {
				el.removeEventListener("load", onImageLoad);
				el.addEventListener("load", onImageLoad);
			}
		});
	});

	observer.observe(document.body, {
		childList: true,
		subtree: true,
	});
});
